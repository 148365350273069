@import "abstracts/variables-bootstrap";
@import "bootstrap";
@import "abstracts/mixins";
@import "abstracts/variables";
@import "partials/fonts";

/** LAYOUT **/
@import "layout/header";
@import "layout/footer";
@import "layout/mainmenu";


/** PAGES **/
@import "pages/cart";
@import "pages/category";
@import "pages/home";
@import "pages/order";
@import "pages/product";

@import "partials/commons";
@import "partials/lazysizes";
/** COMPONENT **/
@import "components/alert";
@import "components/block-social";
@import "components/cart";
@import "components/categories";
@import "components/customer";
@import "components/footer";
@import "components/imageslider";
@import "components/offcanvas";
@import "components/products";
@import "components/searchbar";

@import "components/facet";
@import "components/search-widget";
@import "components/slick";
@import "components/slick-theme";
@import "components/productcomments";
@import "components/utilities";
@import "partials/bs_alpha";

@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');


body p , body input , body ul li , body h1 , body h2 , body h3 , body h4 , body a , body button
{
letter-spacing: 1px;
}

body #boninstagram .instagram-carousel-container .instagram-home-title span
{
  letter-spacing: 1px;
  font-size: 1rem;
  color: #212529;
}


body .owl-theme .owl-nav [class*='owl-'] {
  color: #208094;
  background: transparent;
}

body #boninstagram .instagram-carousel-container .owl-theme .owl-nav [class*="owl-"]:hover {
  background: #6aaae4;
}

.social-share-btn ,
.social-share-btn--footer:hover {
  background-color: transparent;
}

body h1.h1 {
 text-transform: initial;
  font-weight: 600;
  font-size: 1.9rem;
}

.head-title
{
  font-weight: 600;
  font-size: 1.55rem;
  color: white;
  text-align: center;
}

.head-title a
{
  color: white;
}

.head-title.break p
{
  word-spacing: 100vw;
}



.head-title p
{
  line-height: 1.2;
}


.product-add-to-cart
{
  border-bottom: solid 1px #f0f0f0;
  border-top: solid 1px #f0f0f0;
  margin-bottom: 20px;
  padding-top: 20px;

}

.blockreassurance_product .item-product img {
  height: auto!important;
  width: 55px!important;
}

.blockreassurance_product p.block-title {
  padding-left: 15px;
}

.blockreassurance_product .item-product {
  text-align: left;
  width: 65px!important;
  margin-top: 10px;
  padding: 0!important;
  margin-right: 0!important;
}

.social-sharing {
  padding-top: 20px;
  padding-bottom: 20px;
}

.blockreassurance_product .block-title {
  padding-top: 5px;
  margin: 0;
}

.blockreassurance_product>div {
  display: flex;
  flex-wrap: nowrap;
  border-top: 1px solid #ede9e4;
  padding: 10px;
  align-items: center;
  margin-bottom: 15px;
}


.search-widget__group .form-control {
  padding: 0.675rem 1.3rem;
  font-size: 1rem;
  background-color: #e6eaf1;
  border: none;
  border-radius: 25px;
  box-shadow: none;
  height: calc(2.1em + 0.75rem + 2px);
  margin-top: 8px;
}

.form-fields .form-control
{
  padding: 0.675rem 1.3rem;
  font-size: 1rem;
  background-color: #f9f9f9;
  border: none;
  border-radius: 25px;
  box-shadow: none;
  height: calc(2.1em + 0.75rem + 2px);
}


.form-fields textarea.form-control
{
  padding: 0.675rem 1.3rem;
  font-size: 1rem;
  background-color: #f9f9f9;
  border: none;
  border-radius: 25px;
  box-shadow: none;
  height:calc(5.1em + 4rem + 2px);
}


body .page-content form
{
  width: 100%;
  max-width: 100%;
}


.btn-primary:disabled {
  color: white;
  background-color: #002d74;
  border-color: #002d74;
  opacity: 1;
}





.btn-lg {
  font-size: 1.1rem;
  line-height: 1.7;
  border-radius: 25px;
}

.bootstrap-touchspin {
box-shadow: none;
}

.breadcrumb {
  background-color: transparent;
}

.search-widget__group .form-control::placeholder
{
  color: #26406a;
  letter-spacing: 1px;

}


.search-widget__btn.btn
{
  padding: 0.6rem 0.95rem;
  color: #002d74;
}




.visible--mobile.btn
{
color: white;
}

#mobile_top_menu_wrapper .modal-content {
  background-color: #002d74;
}


body .close {
color: white;
}


#header a
{
  color: white;
}

body a
{
  color: #002d74;
}




body a:hover
{
  color: #6aaae4;
}



.text-white a
{
  color: white;
}


.text-white a:hover
{
  color: white;
}



/*
.box .vc_column-inner .wpb_wrapper .wpb_row::before ,
.box-2 .vc_column_container .vc_column-inner .wpb_wrapper .wpb_row::before
{
  background-image: linear-gradient(to top,#335790 0%,rgba(255,255,255,0) 65%);
  z-index: -1;
  display: block !important;
  content: '' ;
  position: absolute ;
  bottom: 0 ;
  left: 0 ;
  top: 0 ;
  right:0 ;
}

.box > * ,
.box-2 > *
{
  z-index: 2;
}


.box .vc_column-inner .wpb_wrapper .vc_custom_heading ,
.box-2 .vc_column-inner .wpb_wrapper .vc_custom_heading
{
  z-index: 3;
}




.vc_col-sm-4.box ,
.vc_col-sm-3.box,
{
  padding-left: 0;
  padding-right: 0;
}

.box .wpb_row ,
.box-2 .wpb_row
{
 margin-bottom: auto !important;
}

.box .vc_row ,
.box-2 .vc_row
{
  margin-left: 0;
  margin-right: 0;
}

 */




.vc_row .box .wpb_column .vc_column-inner:hover ,
.vc_row  .box-2 .wpb_column .vc_column-inner:hover

{
  border-radius: 0;
  background-color: var(--whiteColor);
  box-shadow: 0 15px 35px rgba(0,0,0,0.15);
  transform: translateY(-10px);

}

.vc_row .box .wpb_column .vc_column-inner ,
.vc_row .box-2 .wpb_column .vc_column-inner
{
  -webkit-transition: all 300ms linear 0ms;
  -moz-transition: all 300ms linear 0ms;
  -o-transition: all 300ms linear 0ms;
  transition: all 300ms linear 0ms;
  box-shadow: 0 15px 35px rgba(40,40,40,0.08);
}



.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
text-transform: uppercase;
}


.caddy.img-responsive,
.user.img-responsive {
  width: 22px;

  @include media-breakpoint-up(sm) {
    width: 30px;
  }
}

.menu__item-link--top:hover ,
#header .menu .menu-sub ul li a:hover

{
color: #6aaae4 !important;
}


#header .menu .menu-sub ul li a
{
  color: #212529;
  text-transform: initial;
  font-size: 1rem;
}

body .vc_separator .vc_sep_holder .vc_sep_line {
  top: 0;
}


.txt-box p
{
color: white;
  font-size: 1rem;
  font-weight: 500;
}


.footer__title.footer__title--desktop
{
  font-weight: 700;
  font-size: 1.3rem;
  border-bottom: solid 3px #f0f0f0;
  color: #002d74;
  padding-bottom: 10px;
  margin-bottom: 30px;
  text-transform: initial;
}


.title_block  ,
body #boninstagram .instagram-carousel-container .instagram-home-title h3
{
  text-align: center;
  font-weight: 700;
  font-size: 1.9rem;
  border-bottom: solid 3px #f0f0f0;
  color: #002d74;
  padding-bottom: 10px;
  margin-bottom: 30px;
  text-transform: initial;
}


.block.title_block
{
  text-align: left;
}


.page-header h1
{
  text-align: left;
  font-weight: 700;
  font-size: 1.9rem;
  border-bottom: solid 3px #f0f0f0;
  color: #002d74;
  padding-bottom: 10px;
  margin-bottom: 30px;
  text-transform: initial;
}

h3
{
  text-align: left;
  font-weight: 700;
  font-size: 1.4rem;
  border-bottom: solid 3px #f0f0f0;
  color: black;
  padding-bottom: 10px;
  margin-bottom: 30px;
  text-transform: initial;
}


.step-title.h3
{
  font-weight: 700;
  font-size: 1.4rem;
  color: #002d74;
  text-transform: initial;
}


.card-header
{
  padding: 1.1rem 1.25rem;
}

.page-header--my-account h1 ,
h1.card-header
{
  font-weight: 700;
  font-size: 1.9rem;
  border-bottom: 3px solid #f0f0f0;
  color: #002d74;
  padding-bottom: 10px;
  margin-bottom: 30px;
  text-transform: none;

}


.btn-outline-secondary:hover {
  background-color: #002d74;
  border-color: #002d74;
}

.regular-price {
  color: #6aaae4;
  font-size: 1.4rem;
  font-weight: 700;
}




.shopping-cart
{
  color: #fff;
}


.product-flag.discount-product {
  background: #002d74;
}

body .btn-primary:hover
{
  color: #fff;
  background-color: #6aaae4;
    border-color: #6aaae4;
}

body .btn-outline-primary {
  color: #002d74;
  border-color: #002d74;
}

.btn-primary:focus ,
.btn-primary:not(:disabled):not(.disabled):active
{
  background-color: #6aaae4;
  border-color: #6aaae4;
}

#header .menu__item--current a
{
  color: #6aaae4;
}



.btn-primary {
  background-color: #002d74;
  border-color: #002d74;
  color: white;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #002d74;
  border-color: #002d74;
}



.btn-primary ,
.btn-outline-secondary ,
.btn-outline-primary ,
.btn-light
{
  border-radius: 25px;
}

.page-header {
  background-color: transparent;
  border-bottom: none;
}



.container.footer
{
  border-top: solid 3px #303030;
}

body .card
{
  border: none;
}




.language-selector button
{
color: white;
  font-size: 1rem;
}

.language-selector button:hover ,
#language-selector-label ,
.dropdown-item

{
  color: white;
}

.dropdown-menu.show
{
  background-color: #002d74;
}



.dropdown-item:hover {

  background-color: #6aaae4;
}



body .lgcookieslaw-banner .lgcookieslaw-btn {
  border-color: #6aaae4 !important;
  background: #6aaae4 !important;
}